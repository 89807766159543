import React from 'react';

export default class Contactme extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      text: props.contactMe.CONTACT_ME,
      color: 'white',
      fontSize: '60px',
      overflow: 'auto'
    };

    this.onMouseEnter  = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  _isMounted = false;

  onMouseEnter = e => {
    if(this._isMounted){
      this.setState({text: this.props.contactMe.CONTACT_ME_DETAILS , color:'#FF0000' ,  fontSize: '50px'})
    }
  }

  onMouseLeave = e => {
    if(this._isMounted){
      this.setState({text: this.props.contactMe.CONTACT_ME , color:'white' ,  fontSize: '60px'})
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount(){
      this._isMounted = false;
  }


  render(){
    return(
      <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <h2 className="Contactme" id="ContactMe" style={{
            color: this.state.color ,
            fontSize:this.state.fontSize ,
            overflow: this.state.overflow,
            display: this.state.display}}>
              {this.state.text}
        </h2>
      </div>

    )
  }

}
