import React from 'react';
import { Slide } from "react-slideshow-image";
import building from './slideshow/building.jpg';
import sunset from './slideshow/sunset.jpg';
import trailer from './slideshow/trailer.jpg';

export default class Hobbies extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      title : props.hobbies,
      titleVisibility: 'visibe',
      picVisibility: 'none'
    };
  }

  _isMounted = false;

  onMouseEnter = e => {
    if(this._isMounted){
      this.setState({  visibility: 'hidden', picVisibility: 'block'})
    }
  }

  onMouseLeave = e => {
    if(this._isMounted){
      this.setState({  visibility: 'visible', picVisibility: 'none'})
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  render(){
    const properties = {
      duration: 5000,
      transitionDuration: 500,
      arrows:false,
      infinite: true,
      easing: "ease",
    };

    //Array of pics
    const slideImages = [ building,sunset,trailer];

    return(
      <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <h2 className="Hobbies" id="Hobbies" style={{visibility: this.state.visibility}}> {this.state.title}</h2>
        <div className="Slideshow" style={{display:this.state.picVisibility}}>
          <div className="slide-container">
            <Slide ref={this.slideRef} {...properties} className="Slide">
              {slideImages.map((each, index) => (
                <div key={index} className="each-slide" >
                  <img className="lazy" src={each} alt="sample"  />
                </div>
              ))}
            </Slide>
          </div>
        </div>
      </div>

    )
  }

}
