import React from 'react';

export default class Aboutme extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      aboutMeTitle: props.aboutMe.TITLE,
      fontSize: '60px',
      color:'white'
    };
    this.onMouseEnter  = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  _isMounted = false;

  onMouseEnter = e => {
    if(this._isMounted){
      this.setState({aboutMeTitle: this.props.aboutMe.TEXT, fontSize:'40px',color:'black'})
    }
  }

  onMouseLeave = e => {
    if(this._isMounted){
      this.setState({aboutMeTitle: this.props.aboutMe.TITLE,fontSize:'60px',color:'white'})
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  render(){
    return(
      <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <h2 className="Aboutme" id="Aboutme" style={{fontSize: this.state.fontSize, color:this.state.color}}>{this.state.aboutMeTitle}</h2>
      </div>

    )
  }
}
