import React from 'react';


export default class Title extends React.Component{
  render(){
    return(
        <ul className="Titlelist ">
          <li>
            <a href="#Aboutme" className="X">
              X
            </a>
          </li>
          <li>
            <a href="#Hobbies" className="Y">
              Y
            </a>
          </li>
          <li>
            <a href="#ContactMe" className="Z">
              Z
            </a>
          </li>
        </ul>
      );
  }
}
