import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { CONSTANTS , CONTACT_ME_DATA,ABOUT_ME_DATA} from './data/constants'

import Title from  './title';
import Aboutme from './aboutme';
import Hobbies from './hobbies';
import Contactme from './contactme';
import Madewith from './madewith';
import Footer  from './footer';
import Slideshow from './slideshow/slideshow';


ReactDOM.render(
  <React.StrictMode>
    <Title />
    <Aboutme aboutMe={ABOUT_ME_DATA}/>
    <Hobbies hobbies={CONSTANTS.HOBBIES}/>
    <Slideshow />
    <Contactme contactMe={CONTACT_ME_DATA}/>
    <Madewith madeWith={CONSTANTS.MADE_WITH}/>
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);
