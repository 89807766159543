import React , { Component } from 'react';
import { Slide } from "react-slideshow-image";
import building from './building.jpg';
import sunset from './sunset.jpg';
import trailer from './trailer.jpg';

import "react-slideshow-image/dist/styles.css";


class Slideshow  extends Component {
  constructor() {
    super();
    this.slideRef = React.createRef();
    this.back = this.back.bind(this);
    this.next = this.next.bind(this);
    this.state = {
      current: 0,
      display: 'none',

    };
  }

  _isMounted = false;

  listenScrollEvent = e => {
    if (window.scrollY > 400 &&  window.scrollY < 550) {
      if(this._isMounted){
        this.setState({display:'inline-block'})
      }
    }
    else{
      if(this._isMounted){
        this.setState({display: 'none'})
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  back() {
    this.slideRef.current.goBack();
  }

  next() {
    this.slideRef.current.goNext();
  }

  render(){
    //Setup
    const properties = {
      duration: 5000,
      transitionDuration: 500,
      arrows:false,
      infinite: true,
      easing: "ease",
    };

    //Array of pics
    const slideImages = [ building,sunset,trailer  ];

    return (
      <div className="Slideshow" style={{display:this.state.display}}>
       <div className="slide-container">
         <Slide ref={this.slideRef} {...properties} className="Slide">
           {slideImages.map((each, index) => (
             <div key={index} className="each-slide">
               <img className="lazy" src={each} alt="sample" />
             </div>
           ))}
         </Slide>
       </div>
     </div>
    );
  }

  }

export default Slideshow;
