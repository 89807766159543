import React from 'react';
import {S3Client, GetObjectCommand} from '@aws-sdk/client-s3'
import {getSignedUrl} from '@aws-sdk/s3-request-presigner'

export default class Footer extends React.Component{

  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

    handleClick(){
      const password = prompt("Enter Password: ")
                     .trim()
                     .toLowerCase();
      if(password === process.env.REACT_APP_RESUME_PASSWORD){
        const link = document.createElement('a');
        this.getResumeUrl().then( (url) => {
          link.href = url;
          link.target="_blank"
          link.rel="noreferrer"
          link.download = "Justinn's Resume";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch( (err) => console.log(err))
      }
    }

    async getResumeUrl() {
      const s3Params = {
        region:process.env.REACT_APP_REGION,
        credentials:{
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey:process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
        }
      }
      const client = new S3Client(s3Params);
      const getObjectParams = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: process.env.REACT_APP_BUCKET_KEY
      }
      const command = new GetObjectCommand(getObjectParams);
      return await getSignedUrl(client, command, { expiresIn: 600 });
    }

    render(){
      return(
        <ul className="FooterList">
          <li>
            <a href="https://reactjs.org" className="Reactjs" target="_blank" rel="noreferrer">ReactJs</a>
          </li>
          <li>
            <a href="https://aws.amazon.com" className="AWS" target="_blank" rel="noreferrer">AWS</a>
          </li>
          <li >
            <a href="https://github.com" className="Github" target="_blank" rel="noreferrer">GitHub</a>
          </li>
          <li>
            <button className="Resume" onClick={this.handleClick}>Resume</button>
          </li>
        </ul>
      )
    }
}
