export const CONSTANTS = {
  HOBBIES: 'Y:Hobbies',
  MADE_WITH:'Made With:',
};

export const CONTACT_ME_DATA = {
  CONTACT_ME:'Z:Info',
  CONTACT_ME_DETAILS: 'JustinnDev@gmail.com'
};

export const ABOUT_ME_DATA = {
  TITLE: ' X:About Me',
  TEXT : 'Texas State alumni looking to expand my skills of Computer Science by working to increase company productivity and stability.' +
  'In my spare time I like to practice photography around the city and planning trips around the world.' ,
}
